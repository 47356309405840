import React from "react";
import * as styles from "./header.module.scss";
import Icon from "../images/icons/heart.png";
import Form from "../components/form";


export default class FormBlock extends React.Component {
      constructor(props) {
            super(props)
    
            this.state = {
                formOpen: false,
                  }
            }
            openForm = () => {
                  if (this.state.formOpen === false){
                        {this.setState({ formOpen: true })}
                  } else {this.setState({ formOpen: false })}


            }

      render() {
            if (this.state.formOpen === false)
                  return (
                        <div>
                        <div className={[styles.top, styles.center].join(' ')}>
                              <button  className={styles.tooltip} onClick={this.openForm}>
                                    <img className={styles.heart} src={Icon}></img>
                                    <p className={styles.tooltiptext}>Requests</p>
                              </button>
                        </div>
                  
                        </div>
                  )
            else{
                  return (
                        <div>
                        <div className={[styles.top, styles.center, styles.background].join(' ')}>
                              <button  onClick={this.openForm}>
                                    <img className={styles.heart} src={Icon}></img>
                              </button>
                        </div>
                        <Form></Form>
                        </div>
                  )
            }
      }
}