import React from "react"
import * as styles from "./form.module.scss"


export default class FormBlock extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            name: '' ,
            mail: '' ,
            text: '' ,
            tel: '',
            isSuccessful: "false",
        }
    }

    
    setName = (newValue) => {
        this.setState({ name: newValue.target.value })
    }

    setTel = (newValue) => {
        this.setState({ tel: newValue.target.value })
    }

    setMail = (newValue) => {
        this.setState({ mail: newValue.target.value })
    }

    setText = (newValue) => {
        this.setState({ text: newValue.target.value })
    }

    sendForm = (event) => {
        const mail = document.getElementById('email');
        const name = document.getElementById('name');
        const isValidEmail = mail.checkValidity();
        const isValidName = name.checkValidity();

        if (isValidEmail===true && isValidName===true){
            name.classList.remove('error');
            mail.classList.remove('error');
            this.setState({ isSuccessful: "loading"});

            fetch(
                './api/form',
                 {
                    method: 'POST',
                    // We convert the React state to JSON and send it as the POST body
                    body: JSON.stringify(this.state),
                    headers: {"content-type":"application/json"}
                }
            ).then((response) => {
                response.json().then(console.log)
                if(response.status == 200) 
                    {this.setState({ isSuccessful: "success" })
                } else{this.setState({ isSuccessful: "false"})}
                
            });
                
            event.preventDefault();
        }
        if (isValidEmail === false && isValidName === true){
            mail.classList.add('error');
            name.classList.remove('error');
        }
        if (isValidEmail === false && isValidName === false){
            mail.classList.add('error');
            name.classList.add('error');
        }

        if (isValidName===false && isValidEmail===true){
            name.classList.add('error');
            mail.classList.remove('error');
        } else {
            
        }
    }

    
    render() {
        /**const message = false;*/
        if (this.state.isSuccessful == "success") {
            return (
            <div id="success" className={[styles.center, styles.background].join(' ')}>
                <div>
                    <h2>Danke für deine Nachricht!</h2>
                    <p>Ich werde mich in Kürze bei dir melden!</p>
                </div>
            </div>
            )
        }
        if (this.state.isSuccessful == "loading") {
            return (
            <div id="loader" className={[styles.center, styles.background].join(' ')}>
                <div className={styles.loader}>
                    <div></div>
                    <div></div>
                </div>
            </div>
            )
        }
        else {
            return (<div id="contact">
            <form className={styles.background}>
                <div className={styles.formBackground}>
                    <div className={styles.form}>
                        <div className={styles.formfield}>
                            <label className={styles.labelDefault}>
                                dein name
                                <input type="text" name="Name" id="name" value={this.state.name} onChange={this.setName} required/>
                            </label>
                        </div>
                        <div className={styles.formfield}>
                            <label className={styles.labelDefault}>
                                deine mail
                                <input type="email" id="email" name="Mail" value={this.state.mail} onChange={this.setMail} required/>
                            </label>
                        </div>
                    </div>
                    <div className={styles.formFull}>
                        <div>
                            <label className={styles.labelTextarea}>
                                deine nachricht
                                <textarea name="message" id="message" rows="5" value={this.state.text} onChange={this.setText}/>
                            </label>
                        </div>
                    </div>
                    <div className={styles.formFull}>
                        <div className={styles.mobileWidht}>
                            <label className={styles.labelPhone}>
                                    deine telefonnummer
                                    <input type="tel" name="tel" value={this.state.tel} onChange={this.setTel}/>
                                </label>
                        </div>
                    </div>
                </div>
                <div className={styles.formBackground}>
                    <button 
                        className={[styles.link,styles.linkmneme].join(' ')}
                        type="submit"
                        onClick={this.sendForm}
                    >
                        nachricht senden
                    </button>
                </div>
        </form>
        </div>
        );
        }
    }
}