
import React from "react"



export default function ViewModule({height}){

    const divStyle = {
        height: height + 'px',
    };
  
    

    return (
    <div style={divStyle}></div>
    )

}