// extracted by mini-css-extract-plugin
export var form = "form-module--form--1m2mQ";
export var formFull = "form-module--formFull--3niTs";
export var labelDefault = "form-module--labelDefault--3zEZG";
export var labelPhone = "form-module--labelPhone--3pbio";
export var labelTextarea = "form-module--labelTextarea--1FtdW";
export var formBackground = "form-module--formBackground--4m009";
export var background = "form-module--background--3d9O4";
export var center = "form-module--center--31gPu";
export var formfield = "form-module--formfield--3_IyF";
export var contentitem = "form-module--contentitem--2PcV-";
export var mobileWidht = "form-module--mobileWidht--22ISk";
export var link = "form-module--link--xwd3L";
export var loader = "form-module--loader--3ZGv8";